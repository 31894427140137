import { green, grey, teal } from '@mui/material/colors'
import Logo from '../assets/logo_flexo.svg'
import LogoWhite from '../assets/logo_flexo_white.svg'
import { customYellowFlexo, customBlueFlexo } from './common'

export const defaultTheme = {
  name: 'Default',
  logo: { header: LogoWhite, login: Logo },
  palette: {
    primary: {
      main: customBlueFlexo[800],
      light: customBlueFlexo[800],
      dark: customBlueFlexo[900],
      contrastText: '#ffffff',
    },
    secondary: {
      main: customBlueFlexo[900],
      light: customYellowFlexo[300],
      dark: customYellowFlexo[900],
      contrastText: '#ffffff',
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
    grey: {
      50: '#f8F8F8',
      100: '#f8f9fa',
      200: '#dee2e6',
      300: '#ced4da',
      400: '#e9ecef',
      500: '#8c9094',
      600: '#85909a',
      700: '#7f8a93',
      800: '#6c757d',
      900: '#2a2e32',
    },
    blue: customBlueFlexo,
  },
  header: {
    background: customBlueFlexo[900],
    color: '#ffffff',
    indicator: {
      background: customBlueFlexo[600],
    },
  },
  sidebar: {
    color: grey[800],
    background: '#ffffff',
    borderRight: '0',
    borderColor: grey[200],
    linkActive: {
      color: customBlueFlexo[900],
      background: teal[50],
    },
    linkActiveHover: {
      color: grey[900],
      background: 'red',
    },
    linkOpen: {
      background: teal[50],
      color: customBlueFlexo[900],
    },
    linkOpenHover: {
      background: teal[50],
      color: customBlueFlexo[900],
    },
    header: {
      color: '#ffffff',
      background: customBlueFlexo[900],
    },
    footer: {
      color: '#ffffff',
      background: green[800],
      online: {
        background: '#ffffff',
      },
    },
    badge: {
      color: '#000',
      background: '#ffffff',
    },
  },
  chip: {
    color: '#ffffff',
    background: customYellowFlexo[900],
  },
}
