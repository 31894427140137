import { green, grey, red, teal } from '@mui/material/colors'
import Logo from '../assets/logo_flexo.svg'
import { customYellowFlexo, customBlueFlexo } from './common'

export const lightTheme = {
  name: 'Light',
  logo: { header: Logo, login: Logo },
  palette: {
    mode: 'light',
    primary: {
      main: customBlueFlexo[800],
      light: customBlueFlexo[800],
      dark: customBlueFlexo[900],
      contrastText: '#ffffff',
    },
    secondary: {
      main: customBlueFlexo[900],
      light: customYellowFlexo[300],
      dark: customYellowFlexo[900],
      contrastText: '#ffffff',
    },
    background: {
      default: '#fcfcfc',
      paper: '#ffffff',
    },
    grey: {
      50: '#f8F8F8',
      100: '#f8f9fa',
      200: '#dee2e6',
      300: '#ced4da',
      400: '#adb5bd',
      500: '#8c9094',
      600: '#85909a',
      700: '#7f8a93',
      800: '#6c757d',
      900: '#2a2e32',
    },
    blue: customBlueFlexo,
  },
  header: {
    color: grey[200],
    background: '#ffffff',
    borderBottom: '1px solid',
    borderColor: grey[200],
    search: {
      color: grey[100],
    },
    indicator: {
      background: red[700],
    },
  },
  sidebar: {
    color: grey[800],
    background: '#ffffff',
    borderRight: '1px solid',
    borderColor: grey[200],
    linkActive: {
      color: customBlueFlexo[900],
      background: teal[50],
    },
    linkActiveHover: {
      color: grey[900],
      background: 'red',
    },
    linkOpen: {
      background: teal[50],
      color: customBlueFlexo[900],
    },
    linkOpenHover: {
      background: teal[50],
      color: customBlueFlexo[900],
    },
    scrollbar: {
      borderRight: '0',
      borderColor: 'rgba(0, 0, 0, 0)',
    },
    header: {
      color: '#ffffff',
      background: '#ffffff',
    },
    footer: {
      color: grey[800],
      background: '#F7F7F7',
      online: {
        background: green[500],
      },
    },
  },
  chip: {
    color: '#000',
    background: customYellowFlexo[900],
  },
}
